const MODE = process.env.NODE_ENV
const root = MODE === 'production' ? '' : ''
export default {
  merchandiseImportSkp: {
    url: `${root}/static/template/merchandise/merchandise-import-template-bw.xml`,
    name: '商品信息-百旺税控盘导入模板.xml'
  },
  merchandiseImportJsp: {
    url: `${root}/static/template/merchandise/merchandise-import-template-hx.txt`,
    name: '商品信息-航信金税盘导入模板.txt'
  },
  merchandiseImportZdyJd: {
    url: `${root}/static/template/merchandise/merchandise-import-template-jd.xlsx`,
    name: '商品信息-系统自定义导入模板.xlsx'
  },
  merchandiseImportZdyLs: {
    url: `${root}/static/template/merchandise/merchandise-import-template-ls.xlsx`,
    name: '商品信息-系统自定义导入模板.xlsx'
  },
  customerImportXls: {
    url: `${root}/static/template/customer/customer-import-template.xlsx`,
    name: '客户信息-系统自定义导入模板.xlsx'
  },
  projectImportXls: {
    url: `${root}/static/template/project/project-import-template.xlsx`,
    name: '项目信息-系统自定义导入模板.xlsx'
  },
  systemExplainXsxt: {
    url: `${root}/static/images/explain/menu-xsxt.png`,
    name: '系统介绍-销售协同宣传图片.png'
  },
  systemExplainSpzx: {
    url: `${root}/static/images/explain/menu-spzx.png`,
    name: '系统介绍-收票中心宣传图片.png'
  },
  systemExplainKpgl: {
    url: `${root}/static/images/explain/menu-kpgl.png`,
    name: '系统介绍-开票管理宣传图片.png'
  },
  systemExplainFpzx: {
    url: `${root}/static/images/explain/menu-fpzx.png`,
    name: '系统介绍-发票中心宣传图片.png'
  },
  systemExplainDkrz: {
    url: `${root}/static/images/explain/menu-dkrz.png`,
    name: '系统介绍-抵扣认证宣传图片.png'
  },
  systemExplainCgxt: {
    url: `${root}/static/images/explain/menu-cgxt.png`,
    name: '系统介绍-采购协同宣传图片.png'
  },
  systemExplainBxgl: {
    url: `${root}/static/images/explain/menu-bxgl.png`,
    name: '系统介绍-报销管理宣传图片.png'
  },
  systemExplainGrpj: {
    url: `${root}/static/images/explain/menu-grpj.pdf`,
    name: '系统介绍-个人票据操作手册.pdf'
  },
  invoiceImportXls: {
    url: `${root}/static/template/billing/xls-template.xlsx`,
    name: '发票信息-系统自定义导入模板.xlsx'
  },
  yuNuoImportXls: {
    url: `${root}/static/template/billing/yn-template.xlsx`,
    name: '发票信息-医药业雨诺导入模板.xlsx'
  },
  centerInvoiceImportXls: {
    url: `${root}/static/template/invoice/xls-invoice-center-template.xlsx`,
    name: '发票导入收票模板.xlsx'
  },
  invoiceImportTsv: {
    url: `${root}/static/template/billing/tsv-template.tsv`,
    name: '发票信息-医药业定制导入模板.tsv'
  },
  organizationImportXls: {
    url: `${root}/static/template/organization/organization-import-template.xlsx`,
    name: '组织信息-组织导入模板.xlsx'
  },
  equipmentImportXls: {
    url: `${root}/static/template/equipment/elec-account-import-template.xlsx`,
    name: '数电助手信息-助手导入模板.xlsx'
  },
  userImportXls: {
    url: `${root}/static/template/user/user-import-template.xlsx`,
    name: '用户信息-用户导入模板.xlsx'
  }
}
